'use client';

import { urlHelper } from '@/utils';
import { Button, Card, Center, Group, Stack, Title } from '@mantine/core';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useEffect } from 'react';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Center mt='lg'>
      <Card w='fit-content'>
        <Stack>
          <Title ta='center' order={2} size='h5'>
            An unexpected error occurred!
          </Title>
          <Group justify='center' gap='sm'>
            <Button miw='10em' onClick={reset}>
              Retry
            </Button>
            <Button miw='10em' component={Link} href={urlHelper.home()}>
              Back to Home
            </Button>
          </Group>
        </Stack>
      </Card>
    </Center>
  );
}
